import React, { useState, useEffect } from 'react';
import t from 'prop-types';
import { FormattedMessage } from 'react-intl';
import clsx from 'clsx';
import { isMobile, resolvePlatform } from '../../../../utils/platformResolver';
import { lockBackgroundScroll, unlockBackgroundScroll } from '../../../../utils/lockBackgroundScroll';
import DesktopInstallCol from './DownloadModalComponents/DesktopInstallCol';
import MobileInstallCol from './DownloadModalComponents/MobileInstallCol';
import DesktopPairCol from './DownloadModalComponents/DesktopPairCol';
import MobilePairCol from './DownloadModalComponents/MobilePairCol';
import closeDialogIcon from '../../../../../assets/icons/close-dialog-icon.svg';
import styles from './DownloadModal.scss';

const DESKTOP_SLIDE = 'Desktop';
const MOBILE_SLIDE = 'Mobile';

const DownloadModal = ({ isOpen, onClose, downloadToken, requestNewDownloadToken }) => {
  const [platform] = useState(resolvePlatform());

  const [selectedSlide, setSelectedSlide] = useState(DESKTOP_SLIDE);

  useEffect(() => {
    if (isMobile()) {
      setSelectedSlide(MOBILE_SLIDE);
    }
  }, []);

  useEffect(() => {
    if (isOpen) {
      lockBackgroundScroll();
    } else {
      unlockBackgroundScroll();
    }
  }, [isOpen]);

  if (!isOpen) {
    return null;
  }

  return (
    <div className={styles.overlay}>
      <div className={styles.dialog}>
        <button className={styles.closeDialogButton} onClick={onClose}>
          <img src={closeDialogIcon} alt="Close pop-up button" width="14" height="14" />
        </button>

        <div className={styles.container}>
          <h2 className={styles.title}>
            <FormattedMessage
              id="profile.downloadModal.title"
              defaultMessage="Get started in 2 steps"
              description="Title of the Download Modal"
            />
          </h2>

          <div className={styles.slideControls}>
            <button
              className={clsx(
                styles.slideControlsButton,
                selectedSlide === DESKTOP_SLIDE && styles.slideControlsButtonSelected,
              )}
              onClick={() => setSelectedSlide(DESKTOP_SLIDE)}
            >
              <FormattedMessage
                id="profile.downloadModal.desktopSlide.selector"
                defaultMessage="Desktop"
                description="Button that switches instructions to desktop app installation"
              />
            </button>
            <button
              className={clsx(
                styles.slideControlsButton,
                selectedSlide === MOBILE_SLIDE && styles.slideControlsButtonSelected,
              )}
              onClick={() => setSelectedSlide(MOBILE_SLIDE)}
            >
              <FormattedMessage
                id="profile.downloadModal.mobileSlide.selector"
                defaultMessage="Mobile"
                description="Button that switches instructions to mobile app installation"
              />
            </button>
          </div>

          <div className={styles.viewPort}>
            <div className={clsx(styles.slider, styles[`sliderPos${selectedSlide}`])}>
              <div className={styles.columns}>
                <DesktopInstallCol platform={platform} />
                <DesktopPairCol downloadToken={downloadToken} refreshDownloadToken={requestNewDownloadToken} />
              </div>

              <div className={styles.columns}>
                <MobileInstallCol />
                <MobilePairCol downloadToken={downloadToken} refreshDownloadToken={requestNewDownloadToken} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

DownloadModal.propTypes = {
  isOpen: t.bool.isRequired,
  onClose: t.func.isRequired,
  downloadToken: t.string.isRequired,
  requestNewDownloadToken: t.func.isRequired,
};

export default DownloadModal;
