import { FormattedMessage, useIntl } from 'react-intl';
import { useState } from 'react';
import { Button } from '../../components/common/Button';
import TextInput from '../../components/Enter/otc/components/TextInput';
import BackButton from '../../components/Enter/otc/components/BackButton';
import { getParsedParams } from '../../utils/consts/params';
import { sendChangeEmailVerifyRequest } from '../../utils/api/accountApi';
import { genericErrorMessage } from '../../utils/lang/commonErrors';
import MinimalLayout from '../../components/layout/MinimalLayout';
import { httpResponseCodes } from '../../utils/consts/apiCodes';
import { isApiError } from '../../utils/api/clearApiClient';
import SameEmailErrorModal from './modals/SameEmailErrorModal';
import styles from './ChangeEmailRoute.scss';

const ChangeEmailRoute = () => {
  const intl = useIntl();

  const [errorModalOpen, setErrorModalOpen] = useState(false);

  const [email, setEmail] = useState('');
  const [isValid, setValid] = useState(true);
  const [error, setError] = useState('');
  const [isSubmitting, setSubmitting] = useState(false);

  const onEmailChange = (event: { value: string; isValid: boolean }) => {
    setEmail(event.value);
    setValid(event.isValid);
    setError('');
  };

  const validateEmail = () => {
    if (email.length === 0) {
      setError(
        intl.formatMessage({
          id: 'authScreen.emailSignup.errors.noEmail',
          defaultMessage: 'Email is required',
        }),
      );

      return false;
    }
    if (!isValid) {
      setError(
        intl.formatMessage({
          id: 'authScreen.emailSignup.errors.invalidEmail',
          defaultMessage: 'Email is invalid',
        }),
      );

      return false;
    }

    return true;
  };

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();

    if (!isValid) {
      return;
    }

    const token = getParsedParams().TOKEN;

    if (!token) {
      setError(
        intl.formatMessage({
          id: 'changeEmail.errors.noToken',
          defaultMessage:
            'URL of this page is missing a confirmation code. Please try following the link from the email again, or start the change email process from the beginning.',
        }),
      );

      return;
    }

    setSubmitting(true);
    sendChangeEmailVerifyRequest(email, token).then(
      () => {
        window.location.href = '/change-email/confirm';
      },
      (apiError) => {
        if (isApiError(apiError) && apiError.response?.status === httpResponseCodes.BAD_REQUEST) {
          setErrorModalOpen(true);
        }

        setError(intl.formatMessage(genericErrorMessage));
        setSubmitting(false);
      },
    );
  };

  return (
    <MinimalLayout>
      <div className={styles.wrapper}>
        <BackButton
          disabled={isSubmitting}
          onClick={() => {
            window.location.href = '/profile';
          }}
        />
        <form className={styles.form} onSubmit={handleSubmit}>
          <h3 className={styles.title}>
            <FormattedMessage id="changeEmail.title" defaultMessage="Enter your new email" />
          </h3>
          <TextInput
            value={email}
            handleChange={onEmailChange}
            error={error}
            type="email"
            autoComplete="email"
            disabled={isSubmitting}
            onBlur={validateEmail}
          />

          <Button type="submit" disabled={isSubmitting} kind="primary">
            <FormattedMessage id="changeEmail.submitButton" defaultMessage="Continue" />
          </Button>
        </form>
      </div>

      <SameEmailErrorModal
        isOpen={errorModalOpen}
        onClose={() => {
          setErrorModalOpen(false);
        }}
      />
    </MinimalLayout>
  );
};

export default ChangeEmailRoute;
