import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useQueryAnalytics } from '../../utils/analyticsHooks';
import { sendPermTokenRequest } from '../../utils/api/accountApi';
import { sendProfileRequest } from '../../utils/api/profileApi';
import { sendPaymentLinkRequest, sendAccountCouponRequest } from '../../utils/api/storeApi';
import { getAccessToken, logOut, saveTokens } from '../../utils/auth';
import { useRedirectUrl } from '../../utils/redirectAfterLogin';
import { interceptAuthFromOrder } from '../../utils/authInterceptor';
import { useDownloadToken } from '../../utils/downloadToken';
import { trackProfile } from '../../services/analytics/analyticsProvider';
import Loader from '../common/Loader';
import { getParsedParams, paramsKeys } from '../../utils/consts/params';
import { type UserProfile } from '../../utils/api/schema/profileSchema';
import { handleAuthSuccessEvents } from '../../utils/analytics/authEvents';
import { isApiError } from '../../utils/api/clearApiClient';
import { errorParams } from '../../utils/consts/errorParams';
import ProfileLayout from './layout/ProfileLayout';
import DownloadModal from './deviceManagment/components/DownloadModal';
import DownloadLinksContextProvider from './DownloadLinksContext';
import { isReferralSectionAvailable, type OneOfProfileSections, profileSections } from './profileSections';
import ProfileSectionSelect from './layout/SectionSelect';
import AccountDetailsSection from './accountDetails/AccountDetailsSection';
import BillingInforSection from './billingInfo/BillingInfoSection';
import DeviceManagementSection from './deviceManagment/DeviceManagementSection';
import ReferralSection from './referral/ReferralSection';
import FloatingNotification from './layout/FloatingNotification';
import fixateScrollContainerHeight from './profileLayout';
import styles from './ProfileContainer.scss';

const ProfileContainer = () => {
  const history = useHistory();

  const [activeSection, setActiveSection] = useState<OneOfProfileSections>(profileSections.ACCOUNT);

  const { PERMANENT_TOKEN, ACCESS_TOKEN, REFRESH_TOKEN, REGISTRATION_SOURCE, ADD_NEW_DEVICE } = getParsedParams();

  const [profile, setProfile] = useState<UserProfile | undefined>();
  const [premiumLink, setPremiumLink] = useState('');
  const [coupon, setCoupon] = useState<string | undefined>();
  const [isLoading, setLoading] = useState(true);

  useQueryAnalytics();

  const { resolveRedirectInProfile } = useRedirectUrl();

  const [isDeviceModalOpen, setDeviceModalOpen] = useState(false);
  const closeDeviceModal = () => {
    setDeviceModalOpen(false);
  };

  const { downloadToken, refreshDownloadToken } = useDownloadToken();

  useEffect(() => {
    const fetchProfile = () => {
      resolveRedirectInProfile();

      refreshDownloadToken();

      return Promise.all([
        sendPaymentLinkRequest().then((data) => {
          setPremiumLink(data.data.link);
        }),
        sendAccountCouponRequest().then((res) => {
          setCoupon(res.data.coupon);
        }),
        sendProfileRequest(true).then(
          (data) => {
            setProfile(data.profile);

            handleAuthSuccessEvents(data.profile);

            if (REGISTRATION_SOURCE === 'web_funnel' || ADD_NEW_DEVICE || data.profile.devices.length === 0) {
              setDeviceModalOpen(true);

              trackProfile(data.profile);
            }

            if (data.profile.marked_for_termination) {
              window.location.href = `/enter?error=${errorParams.accountTerminated}`;
            }

            window.Intercom?.('update', {
              email: data.profile.email,
              name: data.profile.full_name,
              user_id: data.profile.account_id,
            });
          },
          (error) => {
            if (
              isApiError(error) &&
              error.response?.data.code === 3 &&
              error.response.data.message === 'AccountDeleted'
            ) {
              window.location.href = `/enter/${paramsKeys.ERROR}=${errorParams.accountTerminated}`;
              throw error;
            } else {
              console.error(error);
              window.location.href = '/h/error';
            }
          },
        ),
      ]).then(() => {
        fixateScrollContainerHeight();
        setLoading(false);
      });
    };

    if (PERMANENT_TOKEN) {
      // if perma token is present exchanging it for the access and refresh ones
      sendPermTokenRequest(PERMANENT_TOKEN)
        .then(interceptAuthFromOrder)
        .then(fetchProfile)
        .catch(() => {
          console.log('Redirecting from /perm-token catch on perm');
          logOut();
        });
    } else if (ACCESS_TOKEN && REFRESH_TOKEN) {
      // saving tokens to cookies, if present
      saveTokens(ACCESS_TOKEN, REFRESH_TOKEN);
      console.log('Saving tokens, access:', getAccessToken());
      if (!interceptAuthFromOrder()) {
        history.replace('/profile');
        fetchProfile().catch((error: unknown) => {
          console.error(error);
          console.log('Redirecting from access-token catch on tokens');
          logOut();
        });
      }
    } else {
      interceptAuthFromOrder();
      fetchProfile().catch((error: unknown) => {
        console.error(error);
      });
    }
  }, []);

  const hasReferralSection = profile && isReferralSectionAvailable(profile);

  return (
    <ProfileLayout
      activeSection={activeSection}
      isReferralSectionAvailable={hasReferralSection}
      onSectionSelected={setActiveSection}
    >
      <DownloadLinksContextProvider downloadToken={downloadToken} refreshDownloadToken={refreshDownloadToken}>
        <div className={styles.container}>
          <ProfileSectionSelect
            activeSection={activeSection}
            isReferralSectionAvailable={hasReferralSection}
            className={styles.sectionSelect}
            onSectionSelected={setActiveSection}
          />

          <main className={styles.content} id="profile-scroll-container">
            <FloatingNotification />
            {isLoading || !profile ? (
              <Loader colored centered size={30} />
            ) : (
              <div className={styles.contentContainer}>
                {activeSection === profileSections.ACCOUNT && <AccountDetailsSection profile={profile} />}
                {activeSection === profileSections.BILLING && (
                  <BillingInforSection profile={profile} premiumLink={premiumLink} coupon={coupon} />
                )}
                {activeSection === profileSections.DEVICES && (
                  <DeviceManagementSection
                    profile={profile}
                    onAddNewDeviceClick={() => {
                      setDeviceModalOpen(true);
                    }}
                  />
                )}
                {activeSection === profileSections.REFERAL && <ReferralSection profile={profile} />}
              </div>
            )}
          </main>
        </div>

        {!isLoading && (
          <DownloadModal
            isOpen={isDeviceModalOpen}
            downloadToken={downloadToken}
            requestNewDownloadToken={refreshDownloadToken}
            onClose={closeDeviceModal}
          />
        )}
      </DownloadLinksContextProvider>
    </ProfileLayout>
  );
};

export default ProfileContainer;
