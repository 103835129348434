import { forwardRef } from 'react';
import styles from './OTCInput.scss';

const codeLength = 6;

interface OTCInputProps {
  disabled: boolean;
  onCodeChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const OTCInput = forwardRef<HTMLInputElement, OTCInputProps>(({ disabled, onCodeChange }, ref) => (
  <div className={styles.otcWrapper}>
    <input
      type="text"
      autoComplete="one-time-code"
      inputMode="numeric"
      minLength={codeLength}
      maxLength={codeLength}
      pattern="\d{6}"
      className={styles.otcInput}
      disabled={disabled}
      ref={ref}
      onChange={onCodeChange}
    />
  </div>
));

OTCInput.displayName = 'OTCInput';

export default OTCInput;
