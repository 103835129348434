import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { defaultLinks as downloadLinks } from '../../../../../utils/downloadLinks';
import { logDownloadStarted } from '../../../../../utils/analytics/downloadEvents';
import dropDownArrow from '../../../../../../assets/icons/download-modal/dropdown-arrow.svg';
import windowsDownloadIcon from '../../../../../../assets/icons/download-modal/windows-download-icon.svg';
import macosDownloadIcon from '../../../../../../assets/icons/download-modal/macos-download-icon.svg';
import desktopMockUp from '../../../../../../assets/icons/download-modal/desktop.png';
import desktopMockUp2x from '../../../../../../assets/icons/download-modal/desktop@2x.png';
import desktopIcon from '../../../../../../assets/icons/download-modal/desktop-icon.svg';
import styles from './DesktopInstallCol.scss';

const DesktopInstallCol = ({ platform }) => {
  const [isDesktopDropdownShown, setDesktopDropdownShown] = useState(false);

  return (
    <section className={styles.desktop}>
      <div className={styles.desktopHeader}>
        <h3>
          <FormattedMessage
            id="profile.downloadModal.desktopSlide.install.title"
            defaultMessage="1. Get ClearVPN for Desktop"
            description="Title of the install column instructions for desktop slide of Download Modal"
          />
        </h3>
        <span>
          <FormattedMessage
            id="profile.downloadModal.desktopSlide.install.subtitle"
            defaultMessage="Available for Windows and macOS"
            description="Subtitle of the desktop half of Download Modal"
          />
        </span>

        <div className={styles.desktopDownloadButton}>
          <a href={downloadLinks[platform]} onClick={() => logDownloadStarted(platform)}>
            {platform === 'windows' ? (
              <FormattedMessage
                id="profile.downloadModal.desktopSlide.install.downloadWindows"
                defaultMessage="Get ClearVPN for Windows"
                description="Download button for Windows"
              />
            ) : (
              <FormattedMessage
                id="profile.downloadModal.desktopSlide.install.downloadMacOS"
                defaultMessage="Get ClearVPN for Mac"
                description="Download button for Mac"
              />
            )}
          </a>
          <div className={styles.desktopDownloadButtonDivider} />
          <button onClick={() => setDesktopDropdownShown(true)}>
            <img src={dropDownArrow} width="16" height="16" alt="" />
          </button>

          {isDesktopDropdownShown ? (
            <div className={styles.desktopDropdown} onMouseLeave={() => setDesktopDropdownShown(false)}>
              <a href={downloadLinks.windows} onClick={() => logDownloadStarted('windows')}>
                <img src={windowsDownloadIcon} width="24" height="24" alt="" />
                <FormattedMessage
                  id="profile.downloadModal.desktopSlide.install.downloadWindows"
                  defaultMessage="Get ClearVPN for Windows"
                  description="Download button for Windows"
                />
              </a>
              <div className={styles.desktopDropdownDivider} />
              <a href={downloadLinks.macos} onClick={() => logDownloadStarted('macos')}>
                <img src={macosDownloadIcon} width="24" height="24" alt="" />
                <FormattedMessage
                  id="profile.downloadModal.desktopSlide.install.downloadMacOS"
                  defaultMessage="Get ClearVPN for Mac"
                  description="Download button for Mac"
                />
              </a>
            </div>
          ) : null}
        </div>
      </div>

      <picture className={styles.mockUp}>
        <img
          src={desktopMockUp}
          srcSet={`${desktopMockUp2x}, 2x`}
          alt="A Windows powered laptop running ClearVPN"
          width="265"
          height="160"
        />
      </picture>

      <div className={styles.desktopVersionInfo}>
        <img src={desktopIcon} width="16" height="16" alt="" />
        {platform === 'windows' ? (
          <span>
            <FormattedMessage
              id="profile.downloadModal.desktopSlide.install.windowsVersionInfo"
              defaultMessage="<b>Works on Windows 10</b> April 2018 Update and higher"
              description="Info label for minimum compatible version of Windows"
              values={{ b: (chunk) => <b>{chunk}</b> }}
            />
          </span>
        ) : (
          <span>
            <FormattedMessage
              id="profile.downloadModal.desktopSlide.install.macosVersionInfo"
              defaultMessage="<b>Works on macOS</b> 11.0 and higher"
              description="Info label for minimum compatible version of macOS"
              values={{ b: (chunk) => <b>{chunk}</b> }}
            />
          </span>
        )}
      </div>
    </section>
  );
};

export default DesktopInstallCol;
