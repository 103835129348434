import React from 'react';
import { FormattedMessage } from 'react-intl';
import LinkCurrentDeviceButton from '../LinkCurrentDeviceButton';
import styles from './DesktopInstallCol.scss';

const DesktopInstallCol = ({ downloadToken, refreshDownloadToken }) => {
  return (
    <section className={styles.desktop}>
      <div className={styles.desktopHeader}>
        <h3>
          <FormattedMessage
            id="profile.downloadModal.desktopSlide.pair.title"
            defaultMessage="2. Connect your account"
            description="Title of the pair column instructions for desktop slide of Download Modal"
          />
        </h3>
        <span>
          <FormattedMessage
            id="profile.downloadModal.desktopSlide.pair.subtitle"
            defaultMessage="Connect your desktop device by pressing the button below"
            description="Subtitle of the pair column instructions for desktop slide of Download Modal"
          />
        </span>
      </div>

      <LinkCurrentDeviceButton
        downloadToken={downloadToken}
        refreshDownloadToken={refreshDownloadToken}
        kind="secondary"
      >
        <FormattedMessage
          id="profile.downloadModal.desktopSlide.pair.pairButton"
          defaultMessage="Pair device"
          description="Button of the section for pairing device with the account, opens app via deeplink to authenticate user with the same account"
        />
      </LinkCurrentDeviceButton>
    </section>
  );
};

export default DesktopInstallCol;
