import { useEffect, useState } from 'react';
import clsx from 'clsx';
import { FormattedMessage } from 'react-intl';
import { profileSections, type OneOfProfileSections } from '../profileSections';
import AccountDetailsIcon from '../sectionIcons/AccountDetailsIcon';
import BillingIcon from '../sectionIcons/BillingIcon';
import DevicesIcon from '../sectionIcons/DevicesIcon';
import ReferralIcon from '../sectionIcons/Referralcon';
import styles from './SectionSelect.scss';

const SectionLabel = ({ section, isSelected }: { section: OneOfProfileSections; isSelected: boolean }) => {
  switch (section) {
    case profileSections.ACCOUNT: {
      return (
        <>
          <AccountDetailsIcon isSelected={isSelected} />
          <FormattedMessage id="profile.accountDetails.title" defaultMessage="Account Details" />
        </>
      );
    }
    case profileSections.BILLING: {
      return (
        <>
          <BillingIcon isSelected={isSelected} />
          <FormattedMessage id="profile.billingInfoSection.title" defaultMessage="Billing Info" />
        </>
      );
    }
    case profileSections.DEVICES: {
      return (
        <>
          <DevicesIcon isSelected={isSelected} />
          <FormattedMessage id="profile.deviceManagementSection.title" defaultMessage="Device management" />
        </>
      );
    }
    case profileSections.REFERAL: {
      return (
        <>
          <ReferralIcon isSelected={isSelected} />
          <FormattedMessage id="profile.referralSection.title" defaultMessage="Refer a friend" />
        </>
      );
    }
    default: {
      return null;
    }
  }
};

interface ProfileSectionSelectProps {
  activeSection: OneOfProfileSections;
  onSectionSelected: (section: OneOfProfileSections) => void;
  isReferralSectionAvailable?: boolean;
  children?: React.ReactNode;
  className?: string;
}

const defaultSections: OneOfProfileSections[] = [
  profileSections.ACCOUNT,
  profileSections.BILLING,
  profileSections.DEVICES,
] as const;

const allSections: OneOfProfileSections[] = [
  profileSections.ACCOUNT,
  profileSections.BILLING,
  profileSections.DEVICES,
  profileSections.REFERAL,
] as const;

const ProfileSectionSelect = ({
  activeSection,
  isReferralSectionAvailable,
  onSectionSelected,
  children,
  className,
}: ProfileSectionSelectProps) => {
  const [availableSections, setAvailableSections] = useState(defaultSections);

  useEffect(() => {
    if (isReferralSectionAvailable) {
      setAvailableSections(allSections);
    } else {
      setAvailableSections(defaultSections);
    }
  }, [isReferralSectionAvailable]);

  return (
    <nav className={clsx(styles.container, className)}>
      {availableSections.map((section) => {
        return (
          <button
            key={section}
            className={clsx(styles.button, activeSection === section && styles.buttonActive)}
            onClick={() => {
              onSectionSelected(section);
            }}
          >
            <SectionLabel section={section} isSelected={activeSection === section} />
          </button>
        );
      })}
      {children}
    </nav>
  );
};

export default ProfileSectionSelect;
