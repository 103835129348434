import React from 'react';
import { FormattedMessage } from 'react-intl';
import masterCardIcon from '../../../../../assets/icons/payment-methods/mastercard.svg';
import visaCardIcon from '../../../../../assets/icons/payment-methods/visa.svg';
import genericCardIcon from '../../../../../assets/icons/payment-methods/genericCreditCard.svg';
import verifiedIcon from '../../../../../assets/icons/payment-methods/verified-card-icon.svg';
import PayPalIcon from '../../../../../assets/icons/payment-methods/paypal.svg';
import ApplePayIcon from '../../../../../assets/icons/payment-methods/applePay.svg';
import GooglePayIcon from '../../../../../assets/icons/payment-methods/googlePay.svg';
import AppStoreIcon from '../../../../../assets/icons/payment-methods/appStore.svg';
import PlayMarketIcon from '../../../../../assets/icons/payment-methods/playMarket.svg';
import MacPawIcon from '../../../../../assets/icons/payment-methods/macpaw.svg';
import styles from './Methods.scss';

const CardIcon = ({ cardType }) => {
  if (cardType === 'visa') {
    return <img src={visaCardIcon} alt="Visa card icon" width="32" height="24" className={styles.methodDetailsIcon} />;
  }
  if (cardType === 'mastercard') {
    return (
      <img src={masterCardIcon} alt="Mastercard icon" width="32" height="24" className={styles.methodDetailsIcon} />
    );
  }

  return (
    <img src={genericCardIcon} alt="Credit card icon" width="24" height="24" className={styles.methodDetailsIcon} />
  );
};

const PaymentMethod = ({ paymentMethod }) => {
  switch (paymentMethod.vendor) {
    case 'card_vendor': {
      return (
        <div className={styles.methodDetails}>
          <CardIcon cardType={paymentMethod.cardType} />
          <span className={styles.methodDetailsStars}>****</span>
          <span className={styles.methodDetailsLast4}>
            {paymentMethod?.card_masked_number?.replaceAll('*', '') || ''}
          </span>
          <img src={verifiedIcon} alt="Verified card icon" width="16" height="16" />
        </div>
      );
    }

    case 'paypal_vendor': {
      return (
        <div className={styles.methodDetails}>
          <img src={PayPalIcon} alt="PayPal icon" width="32" height="24" className={styles.methodDetailsIcon} />
          <span className={styles.methodDetailsLabel}>{paymentMethod.paypal_email_masked}</span>
        </div>
      );
    }

    case 'apple_pay_vendor': {
      return (
        <div className={styles.methodDetails}>
          <img src={ApplePayIcon} alt="Apple Pay icon" width="32" height="24" className={styles.methodDetailsIcon} />
          <span className={styles.methodDetailsLabel}>
            <FormattedMessage
              id="profile.billing.paymentMethod.applePay"
              defaultMessage="Apple Pay"
              description="Label of Apple Pay payment method"
            />
          </span>
        </div>
      );
    }

    case 'google_pay_vendor': {
      return (
        <div className={styles.methodDetails}>
          <img src={GooglePayIcon} alt="Google Pay icon" width="32" height="24" className={styles.methodDetailsIcon} />
          <span className={styles.methodDetailsLabel}>
            <FormattedMessage
              id="profile.billing.paymentMethod.googlePay"
              defaultMessage="Google Pay"
              description="Label of Google Pay payment method"
            />
          </span>
        </div>
      );
    }

    case 'app_store_vendor': {
      return (
        <div className={styles.methodDetails}>
          <img src={AppStoreIcon} alt="App Store icon" width="32" height="24" className={styles.methodDetailsIcon} />
          <span className={styles.methodDetailsLabel}>
            <FormattedMessage
              id="profile.billing.paymentMethod.appStore"
              defaultMessage="App Store subscription"
              description="Label of subscription purchased via App Store"
            />
          </span>
        </div>
      );
    }

    case 'play_marked_vendor': {
      return (
        <div className={styles.methodDetails}>
          <img
            src={PlayMarketIcon}
            alt="Play Market icon"
            width="32"
            height="24"
            className={styles.methodDetailsIcon}
          />
          <span className={styles.methodDetailsLabel}>
            <FormattedMessage
              id="profile.billing.paymentMethod.playMarket"
              defaultMessage="Play Market subscription"
              description="Label of subscription purchased via Android Play Market"
            />
          </span>
        </div>
      );
    }

    case 'platform_payment_vendor': {
      return (
        <div className={styles.methodDetails}>
          <img src={MacPawIcon} alt="MacPaw icon" width="32" height="24" className={styles.methodDetailsIcon} />
          <span className={styles.methodDetailsLabel}>
            <FormattedMessage
              id="profile.billing.paymentMethod.macpawSite"
              defaultMessage="MacPaw Site subscription"
              description="Label of subscription purchased via MacPaw site"
            />
          </span>
        </div>
      );
    }

    default: {
      return '';
    }
  }
};

export default PaymentMethod;
